<template>
  <div>
    <v-navigation-drawer v-model="sidebar" app disable-resize-watcher>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path">
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isAWB" @click="login_awb">
          <v-list-item-content>
            <span class="text-capitalize">Area amministrazione</span>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isLoggedIn" to="/home-services">
          <v-list-item-content>
            <span class="text-capitalize">Home servzi</span>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-btn
            color="primary"
            dark
            :ripple="false"
            depressed
            @click="openLoginDialog"
            v-if="!isLoggedIn">
            <span class="text-capitalize">Log in</span>
          </v-btn>
          <v-btn
            color="primary"
            dark
            :ripple="false"
            depressed
            @click="logout"
            v-else>
            <span class="text-capitalize">Log out</span>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      id="appheader"
      :elevation="0"
      fixed
      color="white"
      style="height: unset !important">
      <template v-slot:prepend></template>
      <img
        src="@/assets/logo.svg"
        class="navbar-logo"
        style="align-self: center"
        @click="$router.push({ name: 'home' })" />
      <v-spacer></v-spacer>
      <div class="d-none d-lg-flex" style="align-items: center">
        <router-link
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          active-class="active-link"
          exact
          class="link px-4">
          {{ item.title }}
        </router-link>
        <span
          v-if="isAWB"
          active-class="active-link"
          exact
          class="link px-4"
          style="cursor: pointer"
          @click="login_awb">
          <span class="text-capitalize">Area amministrazione</span>
        </span>
        <router-link
          v-if="isLoggedIn"
          active-class="active-link"
          exact
          class="link px-4"
          to="/home-services">
          <span class="text-capitalize">Home servizi</span>
        </router-link>
        <v-btn
          color="primary"
          dark
          :ripple="false"
          depressed
          @click="openLoginDialog"
          v-if="!isLoggedIn">
          <span class="text-capitalize">Log in</span>
        </v-btn>
        <v-btn
          color="primary"
          dark
          :ripple="false"
          depressed
          @click="logout"
          v-else>
          <span class="text-capitalize">Log out</span>
        </v-btn>
      </div>
      <div class="d-blck d-lg-none">
        <v-btn icon @click.stop="sidebar = !sidebar">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
      <!-- <template slot="extension">
        <div style="width: 100%" class="banner" v-if="!isLoggedIn">
          <span>Sei interessato a utilizzare i servizi CB Digital?</span>
          <v-btn class="button" ml-15 depressed @click="openRegisterDialog">
            Richiedi qui
          </v-btn>
        </div>
      </template> -->
    </v-app-bar>

    <!-- <v-app-bar color="white" app elevation="0" class="py-1 py-sm-0 d-block">
      <v-flex style="align-items: center">
        <img
          src="@/assets/logo.svg"
          style="width: fit-content"
          class="pointer d-block"
          @click="$router.push({ name: 'home' })" />
        <v-spacer></v-spacer>
        <v-flex text-right class="d-none d-lg-block" style="width: fit-content">
          <button v-if="isAWB" @click="login_awb" class="link px-4">
            {{ isAgent ? "Area amministrazioni" : "Login AWB" }}
          </button>
          <router-link
            v-for="item in menuItems"
            :key="item.title"
            :to="item.path"
            active-class="active-link"
            exact
            class="link px-4">
            {{ item.title }}
          </router-link>
          <v-btn
            color="primary"
            dark
            :ripple="false"
            depressed
            @click="openLoginDialog"
            v-if="!isLoggedIn">
            <span class="text-capitalize">Log in</span>
          </v-btn>
          <v-btn
            color="primary"
            dark
            :ripple="false"
            depressed
            @click="logout"
            v-else>
            <span class="text-capitalize">Log out</span>
          </v-btn>
        </v-flex>
        <div class="d-block d-lg-none">
          <v-btn icon @click.stop="sidebar = !sidebar">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>
        
      </v-flex>
      <div style="width: 100%" class="banner" v-if="!isLoggedIn">
        <span>Sei interessato a utilizzare i servizi CB Digital?</span>
        <v-btn class="button" ml-15 depressed @click="openRegisterDialog"
          >Richiedi qui</v-btn
        >
      </div>
    </v-app-bar> -->
    <div>
      <v-flex xs12 md1>
        <Login
          :value="showLoginDialog"
          @close="closeLoginDialog"
          @success="loginSuccess"></Login>
        <Register
          :value="showRegisterDialog"
          @close="closeRegisterDialog"></Register>
      </v-flex>
    </div>
    <v-snackbar
      v-model="loginSuccessFlag"
      :bottom="true"
      :right="true"
      :timeout="2500"
      color="success">
      Login effettuato con successo!
    </v-snackbar>
    <v-snackbar
      v-model="logoutSuccess"
      :bottom="true"
      :right="true"
      :timeout="2500"
      color="success">
      Logout effettuato con successo!
    </v-snackbar>
  </div>
</template>

<script>
import Login from "@/components/auth/Login";
import Register from "@/components/auth/Register";
import { mapGetters } from "vuex";

export default {
  name: "AppHeader",
  components: {
    Login,
    Register,
  },
  data() {
    return {
      loginSuccessFlag: false,
      logoutSuccess: false,
      showLoginDialog: false,
      showRegisterDialog: false,
      sidebar: false,
    };
  },
  computed: {
    ...mapGetters({
      authState: "getAuthState",
      isAWB: "getIsAWB",
      isAgent: "getIsAgent",
    }),
    isLoggedIn() {
      return this.authState;
    },
    menuItems() {
      let items = [
        { title: "Chi siamo", icon: "mdi-account", path: "/about" },
        { title: "Convenzioni", icon: "mdi-account", path: "/conventions" },
        {
          title: this.isLoggedIn ? "Area marketplace" : "Prodotti",
          icon: "mdi-account",
          path: "/products",
        },
        { title: "Cauzioni", icon: "mdi-account", path: "/guarantees" },
      ];
      if (this.authState) {
        items.splice(4, 0, {
          title: this.isLoggedIn ? "Area emissioni" : "Compagnie",
          icon: "mdi-account",
          path: "/companies",
        });
      }
      return items;
    },
  },
  methods: {
    openLoginDialog() {
      this.sidebar = false;
      this.showLoginDialog = true;
    },
    closeLoginDialog() {
      this.showLoginDialog = false;
    },
    openRegisterDialog() {
      this.showRegisterDialog = true;
    },
    closeRegisterDialog() {
      this.showRegisterDialog = false;
    },
    loginSuccess() {
      this.loginSuccessFlag = true;
      console.log("CIAO " + this.isAgent);

      this.$router.push({ name: "home-services" });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.logoutSuccess = true;
        this.$router.push("/");
      });
    },
    async login_awb() {
      const response = await fetch(
        `${this.$store.getters.getBaseUrl}/loginawb`,
        {
          headers: this.$store.getters.getHeaders,
        }
      );
      if (response.ok) {
        const data = await response.json();
        const link = document.createElement("a");
        link.href = data.url;
        link.style.display = "none";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style>
.banner {
  text-align: center;
  z-index: 100;
  color: white;
  padding: 5px 0 5px 0;
  background-color: #405c6e;
}

.banner .button {
  font-size: 10px;
  height: 25px !important;
  margin-left: 15px;
}

.navbar-logo {
  width: 150px;
  padding: 0 10px;
}

@media (min-width: 600px) {
  .navbar-logo {
    width: 200px;
  }
}

.v-toolbar__extension {
  padding: 0;
  height: unset !important;
}
</style>
